import { media } from '@/stitches.config';
import { useEffect, useState } from 'react';

export function useBreakpoint(breakpoint: keyof typeof media) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(media[breakpoint]);

    const updateMatches = () => {
      setMatches(mediaQueryList.matches);
    };

    // Initial check
    updateMatches();

    // Add event listener for media query changes
    mediaQueryList.addEventListener('change', updateMatches);

    // Clean up event listener on component unmount
    return () => {
      mediaQueryList.removeEventListener('change', updateMatches);
    };
  }, [breakpoint]);

  return matches;
}
