import { useEffect, useState } from 'react';

export function useCanHover() {
  const [canHover, setCanHover] = useState(false);

  useEffect(() => {
    // Media query copied from https://stackoverflow.com/a/42363147
    const mediaQuery = window.matchMedia('not all and (hover: none)');

    setCanHover(mediaQuery.matches);

    const updateIsTouchscreen = (event: MediaQueryListEvent) => {
      setCanHover(event.matches);
    };

    mediaQuery.addEventListener('change', updateIsTouchscreen);

    return () => mediaQuery.removeEventListener('change', updateIsTouchscreen);
  }, []);

  return canHover;
}
