import { BlurImageAnimation } from '@/components/blur-image-animation';
import { styled } from '@/stitches.config';
import { SanityResolvedImage } from '@/types';
import { Gallery as TGallery } from '@/types/sanity';
import { SanityKeyed } from 'sanity-codegen';
import { Carousel } from '../sliders/instances/gallery-carousel/gallery-carousel';
import { GalleryImage } from './gallery-image';
import { Collage } from '@/components/collage';

export type GalleryProps = Omit<TGallery, 'title' | '_type' | 'images'> & {
  images: SanityKeyed<
    SanityResolvedImage<{ alt?: string; caption?: string }>
  >[];
};

export function Gallery(props: GalleryProps) {
  const { images, display, expandable } = props;
  if (display === 'large-carousel' || display === 'small-carousel') {
    return (
      <Carousel
        images={images}
        expandable={expandable}
        hasSliderHeader={false}
        display={display}
      />
    );
  }

  if (display === 'zooming-circles') {
    return <BlurImageAnimation images={images} />;
  }

  if (display === 'collage') {
    return <Collage images={images} />;
  }

  const isStacked = display === 'stacked';

  return (
    <List isFullWidth={display === 'full-width'}>
      {images.map((image) => {
        // Override the aspect ratio if the toggle is active and both width and height are defined
        let aspectRatioWidth = 16;
        let aspectRatioHeight = 9;

        if (image.overrideAspectRatio) {
          aspectRatioWidth = image.asset.metadata.dimensions.width;
          aspectRatioHeight = image.asset.metadata.dimensions.height;
        }

        return (
          <ListItem
            key={image._key}
            layout={display}
            css={
              display !== 'stacked'
                ? {
                    position: 'relative',
                    width: '100%',
                    aspectRatio: `${aspectRatioWidth} / ${aspectRatioHeight}`,
                    '@supports not (aspect-ratio: 1 / 1)': {
                      paddingBottom: `${
                        100 / (aspectRatioWidth / aspectRatioHeight)
                      }%`,
                    },
                  }
                : undefined
            }
          >
            {display === 'stacked' ? (
              <GalleryImage
                image={image}
                isStacked={isStacked}
                expandable={expandable}
                shouldAnimateSlower={true}
              />
            ) : (
              <div>
                <GalleryImage
                  image={image}
                  isStacked={isStacked}
                  expandable={expandable}
                  shouldAnimateSlower={true}
                />
              </div>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}

const List = styled('ol', {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  gap: '$2',
  margin: 0,
  position: 'relative',
  gridColumn: 'wide !important',
  listStyle: 'none !important',
  '@bp4': {
    gap: '$6',
    margin: '0 auto',
  },
  variants: {
    isFullWidth: {
      true: {
        gridColumn: 'full !important',
      },
    },
  },
});

const ListItem = styled('li', {
  flex: '1 1 100%',
  variants: {
    layout: {
      inline: {
        '@bp3': {
          flex: '1 1 10%',
        },
      },

      stacked: {},

      'full-width': {},

      '1x2': {
        '@bp3': {
          flex: '1 0 calc((100% / 2) - $space$3)',
          '&:nth-child(3n + 1)': {
            flex: '1 1 100%',
          },
        },
      },
      '2x2': {
        '@bp3': {
          flex: '1 0 calc((100% / 2) - $space$3)',
        },
      },
      '2x3': {
        '@bp3': {
          flex: '1 0 calc((100% / 3) - $space$3 * 2)',
          '&:nth-child(5n), &:nth-child(5n -1)': {
            flex: '1 0 calc((100% / 2) - $space$3)',
          },
        },
      },
      '3x4': {
        '@bp3': {
          flex: '1 0 calc((100% / 4) - $space$3 * 3)',
          '&:nth-child(7n), &:nth-child(7n -1), &:nth-child(7n -2)': {
            flex: '1 1 calc((100% / 3) - $space$3 * 2)',
          },
        },
      },
    },
  },
});
