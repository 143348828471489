import { useCanHover } from '@/hooks/use-can-hover';
import { Box } from '../box';
import { GridContainer } from '../grid-container';
import { Service as ServiceType } from '@/types/sanity';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Text } from '../text';
import { CSS } from '@/stitches.config';
import { StaggeredText } from '../staggered-text';
import { EnterAnimation } from '../enter-animaton';
import { Solutions } from './solutions';

interface ServiceProps {
  serviceData: ServiceType;
  css?: CSS;
}

export function Service({ serviceData, css }: ServiceProps) {
  const canHover = useCanHover();
  const isLargeScreen = useBreakpoint('bp5');
  const isFullWidth = !useBreakpoint('bp3');

  const solutionType = isLargeScreen && canHover ? 'card' : 'accordionItem';

  return (
    <Box css={css}>
      <GridContainer>
        <GridContainer>
          <Box
            css={{
              gridColumn: '1 / -1',
              display: 'flex',
              flexDirection: 'column',
              '@bp3': {
                gridColumn: '6 / -2',
              },
            }}
          >
            <Text size={6} css={{ marginBottom: '$9' }}>
              <StaggeredText text={serviceData.title} />
            </Text>
            <EnterAnimation
              css={{
                marginBottom: '$5',
              }}
            >
              <Text size={4} css={{ fontWeight: '$heavy' }}>
                What we do
              </Text>
            </EnterAnimation>
            <EnterAnimation
              css={{
                marginBottom: '$8',
              }}
            >
              <Text size={4}>{serviceData.description}</Text>
            </EnterAnimation>
            <EnterAnimation
              css={{
                marginBottom: '$7',
              }}
            >
              <Text size={4} css={{ fontWeight: '$heavy' }}>
                Solutions
              </Text>
            </EnterAnimation>
            {!isFullWidth && (
              <Solutions
                solutionType={solutionType}
                solutionsData={serviceData.solutions}
              />
            )}
          </Box>
        </GridContainer>
      </GridContainer>
      {isFullWidth && (
        <Solutions
          solutionType={solutionType}
          solutionsData={serviceData.solutions}
        />
      )}
    </Box>
  );
}
