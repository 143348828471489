import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import type { KeenSliderOptions } from 'keen-slider/react';
import { ResizePlugin } from '@/lib/keen-resize-plugin';

import { styled } from '@/stitches.config';
import type { CSS } from '@/stitches.config';

import { Box } from '@/components/box';

import { Arrows } from './arrows';

const SliderGrabber = styled('div', {
  overflow: 'visible !important',
  variants: {
    dragging: { true: {} },
    draggable: {
      true: {
        cursor: 'grab',
      },
    },
  },
  compoundVariants: [
    {
      draggable: true,
      dragging: true,
      css: {
        cursor: 'grabbing',
      },
    },
  ],
});

type SliderComposeableProps = {
  slides: React.ReactElement[];
  config?: KeenSliderOptions;
  css?: CSS;
};

const slidesConfig = {
  spacing: 24,
  perView: 10,
};

const breakpointsConfig = {
  '(min-width: 40em)': {
    slides: {
      perView: 2.5,
    },
  },
  '(min-width: 52.5em)': {
    slides: {
      perView: 3.5,
    },
  },
  '(min-width: 64em)': {
    slides: {
      perView: 4.5,
    },
  },
};

const defaultConfig = {
  loop: false,
  mode: 'free-snap',
  slides: slidesConfig,
  breakpoints: breakpointsConfig,
} as KeenSliderOptions;

export function SliderComposeable(props: SliderComposeableProps) {
  const { slides, config = defaultConfig, css } = props;
  const SHOW_ARROWS = true;

  const initialSlide = 0;

  const [isDragging, setDragging] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [loaded, setLoaded] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      mode: 'free-snap',
      initial: initialSlide,
      ...config,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      dragStarted() {
        setDragging(true);
      },
      dragEnded() {
        setDragging(false);
      },
      detailsChanged(slider) {
        if (slider.slides.length < 1) {
          setIsDraggable(false);
          return;
        }

        // If the collective length of the slides exceeds the length of the
        // carousel, the carousel is draggable

        const slidesLength =
          slider.slides[slider.slides.length - 1].getBoundingClientRect()[
            slider.options.vertical ? 'bottom' : 'right'
          ] -
          slider.slides[0].getBoundingClientRect()[
            slider.options.vertical ? 'top' : 'left'
          ];

        setIsDraggable(
          slidesLength >
            slider.container[
              slider.options.vertical ? 'offsetHeight' : 'offsetWidth'
            ]
        );
      },
    } as KeenSliderOptions,
    [ResizePlugin]
  );

  return (
    <Box
      css={{
        position: 'relative',
        gridColumn: 'full / full',
        overflow: 'hidden',
        ...css,
      }}
    >
      {/* Core wrapper */}
      <SliderGrabber
        ref={sliderRef}
        className="keen-slider"
        dragging={isDragging}
        draggable={isDraggable}
      >
        {/* Loop over the children and add the Slide container around it */}
        {React.Children.map(slides, (child) =>
          React.cloneElement(child, {
            className: 'keen-slider__slide',
          })
        )}
      </SliderGrabber>

      {/* Side arrows */}

      {SHOW_ARROWS && (
        <Arrows
          currentSlide={currentSlide}
          loaded={loaded}
          instanceRef={instanceRef}
        />
      )}
    </Box>
  );
}
