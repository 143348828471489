import { useEffect, useState } from 'react';

export function useDimensions() {
  const [dimensions, setDimensions] = useState<{
    width: number | null;
    height: number | null;
  }>({ width: null, height: null });

  const [element, setElement] = useState<Element | null>();

  useEffect(() => {
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries[0]) {
        return;
      }
      const rect = element.getBoundingClientRect();
      setDimensions({
        width: rect.width,
        height: rect.height,
      });
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [element]);

  return {
    element,
    setElement,
    width: dimensions.width,
    height: dimensions.height,
  };
}
