import { Box } from '../box';
import { GalleryProps } from '../serializers/gallery';
import { CollageItem } from './collage-item';

export function Collage({ images }: Pick<GalleryProps, 'images'>) {
  if (images.length !== 4) {
    throw Error(
      `Trying to pass ${images.length} images to collage. Collage must contain exactly 4 images.`
    );
  }

  return (
    <Box
      css={{
        $$gap: '$space$2',
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        gap: '$$gap',
        width: '100%',
        aspectRatio: '1 / 1',
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          gap: '$$gap',
        },
        '& > div > div': {
          flexGrow: 1,
        },
        '& > div:first-child > div:first-child, & > div:last-child > div:last-child':
          {
            flexGrow: 2,
          },
        '@bp4': {
          $$gap: '$space$6',
        },
      }}
    >
      <Box css={{ flexBasis: '60%' }}>
        {images.slice(0, 2).map((image) => (
          <CollageItem key={image._key} image={image} />
        ))}
      </Box>
      <Box css={{ flexGrow: 1 }}>
        {images.slice(2, images.length).map((image) => (
          <CollageItem key={image._key} image={image} />
        ))}
      </Box>
    </Box>
  );
}
