import {
  PointerEvent as ReactPointerEvent,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '../box';
import { GalleryProps } from '../serializers/gallery';
import { Text } from '../text';
import { TreemapImage } from '../treemap-image';
import { computePosition, flip, offset } from '@floating-ui/dom';
import { useCanHover } from '@/hooks/use-can-hover';
import { Close } from '../icons';
import { motion, Point } from 'framer-motion';

interface CollageItemProps {
  image: GalleryProps['images'][number];
}

export function CollageItem({ image }: CollageItemProps) {
  const captionRef = useRef<HTMLSpanElement | null>(null);
  const [isCaptionVisible, setIsCaptionVisible] = useState(false);
  const [isPointerPositionKnown, setIsPointerPositionKnown] = useState(false);
  const pointerPositionRef = useRef<Point | null>(null);

  const canHover = useCanHover();

  useLayoutEffect(() => {
    const captionElement = captionRef.current;

    if (!captionElement || !isCaptionVisible) return;

    captionElement.style.setProperty('visibility', 'hidden');

    const updateTooltipPosition = () => {
      if (!pointerPositionRef.current) return;

      const virtualEl = {
        getBoundingClientRect: () => ({
          x: pointerPositionRef.current?.x ?? 0,
          y: pointerPositionRef.current?.y ?? 0,
          top: pointerPositionRef.current?.y ?? 0,
          left: pointerPositionRef.current?.x ?? 0,
          bottom: window.innerHeight - (pointerPositionRef.current?.y ?? 0),
          right: window.innerWidth - (pointerPositionRef.current?.x ?? 0),
          width: 0,
          height: 0,
        }),
      };

      computePosition(virtualEl, captionElement, {
        placement: 'bottom-start',
        middleware: [
          offset({ mainAxis: 12, alignmentAxis: 12 }),
          flip({ padding: 12 }),
        ],
      }).then(({ x, y }) => {
        setIsPointerPositionKnown(true);
        captionElement.style.setProperty('visibility', null);
        captionElement.style.setProperty(
          'transform',
          `translate(${x}px, ${y}px)`
        );
      });
    };

    const onPointerMove = (event: PointerEvent) => {
      if (!pointerPositionRef.current) {
        pointerPositionRef.current = {
          x: event.x,
          y: event.y,
        };
      } else {
        pointerPositionRef.current.x = event.x;
        pointerPositionRef.current.y = event.y;
      }

      updateTooltipPosition();
    };

    updateTooltipPosition();

    window.addEventListener('pointermove', onPointerMove);
    window.addEventListener('scroll', updateTooltipPosition);

    return () => {
      window.removeEventListener('pointermove', onPointerMove);
      window.removeEventListener('scroll', updateTooltipPosition);
      captionElement.style.setProperty('visibility', 'hidden');
      setIsPointerPositionKnown(false);
      pointerPositionRef.current = null;
    };
  }, [isCaptionVisible]);

  const showCaption = (event: ReactPointerEvent) => {
    pointerPositionRef.current = {
      x: event.clientX,
      y: event.clientY,
    };
    setIsCaptionVisible(true);
  };

  return (
    <Box
      css={{ position: 'relative' }}
      onPointerEnter={canHover ? showCaption : undefined}
      onPointerLeave={canHover ? () => setIsCaptionVisible(false) : undefined}
      onPointerCancel={canHover ? () => setIsCaptionVisible(false) : undefined}
      onPointerOver={canHover ? showCaption : undefined}
      onClick={canHover ? undefined : () => setIsCaptionVisible((x) => !x)}
    >
      <TreemapImage
        src={image.asset.url}
        palette={image.asset.metadata.palette}
        layout="fill"
      />

      {canHover && isCaptionVisible && (
        <Text
          key={image.caption}
          as={motion.span}
          ref={captionRef}
          size={1}
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            color: '$background',
            backgroundColor: '$foreground',
            px: '0.75rem',
            paddingBlockStart: '0.35rem',
            paddingBlockEnd: '0.25rem',
            zIndex: 10,
          }}
          variants={{
            hide: {
              opacity: 0,
            },
            show: {
              opacity: 1,
            },
          }}
          initial="hide"
          animate={isPointerPositionKnown ? 'show' : 'hide'}
        >
          {image.caption}
        </Text>
      )}

      {!canHover && isCaptionVisible && (
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            padding: '$5',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
          }}
        >
          <Text size={1} css={{ margin: 0 }}>
            {image.caption}
          </Text>
        </Box>
      )}

      {!canHover && (
        <Box
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '$4',
            aspectRatio: '1 / 1',
            backgroundColor: '$foreground',
            color: '$background',
            '& svg': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 10,
              height: 10,
            },
          }}
        >
          {isCaptionVisible ? (
            <Close />
          ) : (
            <Text
              css={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -45%)',
                fontSize: 10,
              }}
            >
              i
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
}
