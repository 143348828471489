import { Box } from '../box';
import { EnterAnimation } from '../enter-animaton';
import { Service as ServiceType } from '@/types/sanity';
import { SolutionCard } from './solution-card';
import { SolutionAccordionItem } from './solution-accordion-item';

interface SolutionsProps {
  solutionType: 'card' | 'accordionItem';
  solutionsData: ServiceType['solutions'];
}

export function Solutions({ solutionType, solutionsData }: SolutionsProps) {
  const SolutionComponent =
    solutionType === 'card' ? SolutionCard : SolutionAccordionItem;

  return (
    <EnterAnimation>
      <Box
        css={
          solutionType === 'card'
            ? {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gridGap: '$4',
              }
            : undefined
        }
      >
        {solutionsData.map((solutionData) => (
          <SolutionComponent
            key={solutionData._key}
            solutionData={solutionData}
          />
        ))}
      </Box>
    </EnterAnimation>
  );
}
